export function switchForm() {
  // お申し込み ラジオボタン ゆくくるサーベイに申し込む
  const applicationBtn = document.getElementById('application-type1')
  // お申し込み ラジオボタン お問い合わせ
  const contactBtn = document.getElementById('application-type2')

  // お問い合わせ（textarea） tr
  const remarksTableRow = document.getElementById('application-other__wrap')
  // お問い合わせ（textarea） 必須ラベル
  const remarksLabel = remarksTableRow.querySelector(
    'label .p-contact-form__required',
  )
  // お問い合わせ（textarea）
  const remarks = remarksTableRow.querySelector('textarea')

  function removeRemarksRequired() {
    // お問い合わせ（textarea） required属性削除
    if (remarks.hasAttribute('required')) {
      remarks.removeAttribute('required')
    }
    // お問い合わせ（textarea） 非表示
    remarksLabel.classList.add('js-form-hidden')
  }

  function setRemarksRequired() {
    // お問い合わせ（textarea） required属性追加
    remarks.setAttribute('required', '')
    // お問い合わせ（textarea） 表示
    if (remarksLabel.classList.contains('js-form-hidden')) {
      remarksLabel.classList.remove('js-form-hidden')
    }
  }

  //
  // お問い合わせの種類
  //

  // お申し込み
  // お問い合わせを任意項目に
  applicationBtn.addEventListener(
    'change',
    () => {
      if (applicationBtn.checked === true) {
        removeRemarksRequired()
      }
    },
    false,
  )

  // お問い合わせ
  // お問い合わせを必須項目に
  contactBtn.addEventListener(
    'change',
    () => {
      if (contactBtn.checked === true) {
        setRemarksRequired()
      }
    },
    false,
  )
}
