// APIキー
const MICROCMS_API_KEY = '29268e39-74b9-4bea-bcb2-bd2a9fd58e61'
const MICROCMS_URL =
  'https://yukukuru-online.microcms.io/api/v1/entry/mmv0p5n4e'

const entryContents = document.querySelector('.js-entry')
const entryDate = document.querySelector('.js-entry-date')
const entryUrl = document.querySelector('.js-entry-url')

async function getEntryContents() {
  let response
  // リクエストヘッダーにAPIキーを設定
  try {
    response = await fetch(MICROCMS_URL, {
      headers: {
        'X-API-KEY': MICROCMS_API_KEY,
      },
    })
  } catch (err) {
    throw new Error(`yukukuru-lp/ microCMS ${err.message}`)
  }
  const responseJson = await response.json()
  if (!responseJson.date || !responseJson.url) {
    // CMSにデータがない時はエリア非表示
    entryContents.classList.add('is-hide')
  } else {
    // 開催日時
    entryDate.textContent = responseJson.date
    // URL
    entryUrl.setAttribute('href', responseJson.url)
  }
}
export default getEntryContents
