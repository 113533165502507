// select 都道府県
const prefecturesSelect = document.getElementById('application-prefectures')
// select 市区町村
const municipalitiesSelect = document.getElementById(
  'application-municipalities',
)
// APIキー
const RESAS_API_KEY = 'by6luJKlOHA84SkMUOaJXNfUx7jmyS5wLUexKc4K'
// 都道府県一覧 URL
const prefecturesUrl = 'https://opendata.resas-portal.go.jp/api/v1/prefectures'

async function Resas() {
  let prefecturesResponse
  // リクエストヘッダーにAPIキーを設定
  try {
    prefecturesResponse = await fetch(prefecturesUrl, {
      headers: {
        'X-API-KEY': RESAS_API_KEY,
      },
    })
  } catch (err) {
    throw new Error(`yukukuru-lp/ RESAS-API ${err.message}`)
  }
  const prefecturesJson = await prefecturesResponse.json()
  // 都道府県 データ
  const prefecturesData = prefecturesJson.result
  // console.log(prefecturesData)

  // 都道府県 option 配列
  let prefecturesOptions = prefecturesData.map((array) => {
    return `<option value="${array.prefName}">${array.prefName}</option>`
  })

  // html 追加
  prefecturesOptions.forEach((option) => {
    prefecturesSelect.insertAdjacentHTML('beforeend', option)
  })

  // 都道府県選択時に市区町村のoptionを削除
  prefecturesSelect.addEventListener('change', () => {
    const municipalitiesChild = municipalitiesSelect.querySelectorAll('option')
    // option 選択してください以外を削除
    if (municipalitiesChild.length > 1) {
      for (let index = 1; index < municipalitiesChild.length; index++) {
        municipalitiesChild[index].remove()
      }
    }
  })

  // 選択したoptionの都道府県コードをURLに追加
  prefecturesSelect.addEventListener('change', async function (event) {
    // urlパラメーター（prefCode）
    let urlParam = prefecturesData.find((prefecture) => {
      return prefecture.prefName === event.target.value
    })

    // 市区町村一覧 URL
    let municipalitiesUrl = `https://opendata.resas-portal.go.jp/api/v1/cities?prefCode=${urlParam.prefCode}`
    //
    let municipalitiesResponse
    // リクエストヘッダーにAPIキーを設定
    try {
      municipalitiesResponse = await fetch(municipalitiesUrl, {
        headers: {
          'X-API-KEY': RESAS_API_KEY,
        },
      })
    } catch (err) {
      throw new Error(`yukukuru-lp/ RESAS-API ${err.message}`)
    }
    const municipalitiesJson = await municipalitiesResponse.json()
    // 市区町村 データ
    const municipalitiesData = municipalitiesJson.result
    // console.log(municipalitiesData)

    // 市区町村 option 配列
    let prefecturesOptions = municipalitiesData.map((array) => {
      return `<option value="${array.cityName}">${array.cityName}</option>`
    })

    // html 追加
    prefecturesOptions.forEach((option) => {
      municipalitiesSelect.insertAdjacentHTML('beforeend', option)
    })
  })
}

export default Resas
