// https://www.npmjs.com/package/smoothscroll-polyfill
// safari IE Edge
import smoothscroll from 'smoothscroll-polyfill'

export function smoothScroll() {
  smoothscroll.polyfill()
  const scrollLinks = document.querySelectorAll('.js-smoothScroll')
  const header = document.querySelector('.js-header')
  const spHeader = document.querySelector('.js-sp-header')

  scrollLinks.forEach((link) => {
    // click
    link.addEventListener(
      'click',
      (event) => {
        // イベントキャンセル
        event.preventDefault()
        event.stopPropagation()

        // スクロール先のhash
        let hash = event.target.hash

        window.scroll({
          top: setSmoothScrollPosition(hash),
          behavior: 'smooth',
        })
      },
      false,
    )
  })
  // スクロール先を取得
  function setSmoothScrollPosition(element) {
    // スクロール先の要素
    let targetElement = document.querySelector(element)

    // header の高さ
    let pcHeaderHeight = Math.floor(header.getBoundingClientRect().height)
    let spHeaderHeight = Math.floor(spHeader.getBoundingClientRect().height)
    let headerHeight = pcHeaderHeight || spHeaderHeight

    // スクロール先の要素の位置 - header の高さ
    let targetElementPosition = Math.floor(
      (window.scrollY || window.pageYOffset) + // IE11対応
        targetElement.getBoundingClientRect().top,
    )

    return targetElementPosition - headerHeight
  }
}
