import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export function spHeaderNav() {
  // 開いている時
  let menuOpen = false

  const button = document.querySelector('.js-sp-header-button')
  const nav = document.querySelector('.js-sp-header-nav')
  const overlay = document.querySelector('.js-sp-header-nav__overlay')
  const links = document.querySelectorAll('.js-sp-header-nav-link')

  function closeMenu() {
    button.classList.remove('is-open')
    nav.classList.remove('is-open')
    menuOpen = false
    enableBodyScroll(nav)
  }

  function showMenu() {
    button.classList.add('is-open')
    nav.classList.add('is-open')
    menuOpen = true
    disableBodyScroll(nav)
  }

  // 開閉
  button.addEventListener(
    'click',
    () => {
      if (menuOpen) {
        closeMenu()
      } else {
        showMenu()
      }
    },
    false,
  )

  // 閉じる
  overlay.addEventListener(
    'click',
    () => {
      if (menuOpen) {
        closeMenu()
      }
    },
    false,
  )

  // 閉じる
  links.forEach((link) => {
    link.addEventListener(
      'click',
      () => {
        if (menuOpen) {
          closeMenu()
        }
      },
      false,
    )
  })
}
