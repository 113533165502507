export function airbrakeNotify() {
  const Airbrake = require('@airbrake/browser')
  const airbrake = new Airbrake.Notifier({
    projectId: 249519,
    projectKey: '6035842a853dabe569aac80556b0f063',
    environment: 'production',
  })
  airbrake.addFilter((notice) => {
    if (process.env.NODE_ENV === 'development') {
      // Ignore errors from admin sessions.
      return null
    }
    notice.context.environment = process.env.NODE_ENV
    return notice
  })
}
